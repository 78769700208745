.zoom-container {
    // position: absolute;
    // top: 50px;
    // left: 321px;
    // left: 284px;
    display: flex;
    padding: 3px 0 3px 0;
    z-index: 1;
    opacity: 0.85;

    .p-button {
        height: 44px;
        width: 44px;
        // margin-bottom: 5px;
    }

    .pi-home,.pi-arrow-circle-right, .pi-plus,.pi-minus {
        
        color: white;
      }
    //   .zoom-container .p-button.p-button-sm .p-button-icon {
    //     font-size: 0.975rem;
    // }
    
}