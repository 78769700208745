@import '../../index.scss';

.Login {
    background-image: url("../../../public/icons/login-background.png");
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 80px;
    background-size: cover;

    form {
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;


        .recaptcha {

            display: flex;
            align-items: center;
            justify-content: center;
            width: 360px;
            height: 170px;

            .password-area {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 3px;

                #password-image {
                    border-radius: 5px;
                }
            }

            .renew {
                .p-button {
                    height: 50px !important;
                    width: 50px !important;
                }

                .default-button {
                    color: white !important;
                }
            }

            .recaptcha-form {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 250px;
                height: 150px;
                justify-content: center;


                span {
                    font-size: 15px;
                }

                .renew-area {
                    display: flex;
                }

                .recaptcha-container {
                    display: flex;
                    margin-top: 10px;
                    align-items: center;
                    flex-direction: column;

                    .confirmation-screen {
                        display: flex;
                        flex-direction: column;
                        .password-box {
                            background-color: white;
                            color: black;
                            // width: 185px;
                            width: 240px;
                            // height: 38.5px;
                            height: 50px;
                            margin-right: 3px;
                        }
                        .security-info{
                            margin-top: 10px;
                        }

                        .default-button {
                            color: white !important;
                            height: 50px !important;
                            width: 50px !important;
                        }

                        .check-button {
                            .true-button {
                                color: white !important;
                                background-color: green !important;
                                border: none !important;
                                height: 50px !important;
                                width: 50px !important;
                            }
                        }
                    }
                }

                span {
                    padding: 2px 0 2px 0;
                    color: white;
                }

                .p-inputtext {
                    width: 150px;
                }
            }
        }


    }

    .content {
        display: flex;
        flex-direction: column;
        user-select: none;

        .header {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                width: 507px;
                height: 400px;
            }
        }

        .login-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 9px;
            width: 507px;
            height: 350px;

            .input-group {

                .password-section {
                    margin-bottom: 10px !important;
                }

                .p-field {
                    margin-bottom: 35px;

                    .field-input {
                        .login-input {
                            width: 360px;
                        }
                    }
                }
            }

            .group {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 8% 0 6% 0;

                .p-col-12 {
                    display: flex;
                    align-items: center;

                    #remember-me {
                        display: flex;
                        align-items: center;
                        margin-right: 6px;
                    }

                    label {
                        white-space: nowrap;
                    }
                }

                .forgot-pass {
                    color: white;
                    font-weight: bold;
                }
            }

            .submit-button {
                width: 100%;
                margin-top: 10px;
                // margin-top: 30px;
                display: flex;
                justify-content: center;

                .submit-login {
                    // width: 150px !important;
                    // background-color: #61a6c6 !important;
                    // color: white;
                    // border: none !important;
                    // color: white;
                    // border-radius: 12px;
                    // font-size: 18px;

                    width: 360px !important;
                    background-color: #61a6c6 !important;
                    color: white;
                    border: none !important;
                    color: white;
                    border-radius: 6px;
                    font-size: 18px;

                    &:hover {
                        background: #5896b3 !important;
                    }
                }
            }
        }

    }

    .Login {
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        .entry-exit {
            padding: 9px 0px 9px 0px !important;
            width: 38px !important;
        }

        .pi-sign-in {
            font-size: 17px !important;
        }
    }

    .p-password input {
        width: 32rem;
    }

    .pi-eye:hover {
        cursor: pointer !important;
    }

    .pi-eye-slash:hover {
        cursor: pointer !important;
    }
}