@import '../../index.scss';

.dropdown-group {
    position: absolute;
    top: 50px;
    // left: 182px;
    left: 142px;
    display: flex;
    flex-direction: column;
    background-color: $secondaryColor;
    width: 255px;
    padding: 3px 0 6px 0;
    // padding: 3px 0 3px 0;
    border-radius: 6px;
    z-index: 1;

    
    .p-dropdown {
        box-shadow: 0px 3px 6px lightgray;
        height: 38px;
        border: none;
        display: flex;
        border-radius: 0;
        border: 0;
        color: $secondaryColor !important;
        background-color: $primaryColor;
        width: 245px !important;
        margin-bottom: 6px;
        border-radius: 5px;
        margin: 2px 5px 3px 5px;
    }
    
    .p-dropdown-trigger {
        color: $secondaryColor !important;
        background-color: $primaryColor;
    }
    
    .p-dropdown-label.p-inputtext {
        color: $secondaryColor !important;
    }

    .p-dropdown-clear-icon.pi.pi-times {
        color: $secondaryColor !important;
    }
    
    .p-dropdown-panel.p-dropdown-items.p-dropdown-item.p-highlight {
        color: $primaryColor !important;
    }
}

::-webkit-scrollbar {
    width: 3px !important;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #286688;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $primaryColor !important;
    width: 10px !important;
    border-radius: 10px;
}

.coordinate-section{
    display: flex;
    justify-content: center;
    margin-top: 1%;
    border-top: 1px solid #ccd5d8;
    padding-top: 2%;
}

.coordinate-button{
    width: 255px !important;
}
// .p-dropdown {
//     color: $secondaryColor;
//     background-color: $primaryColor;
// }


// .p-dropdown-clear-icon {
    //     font-size: 12px;
//     top: 21px;
// }

// .p-dropdown-panel {
//     color: $secondaryColor;
// }

// .p-dropdown:not(.p-disabled):hover {
//     border-color: $secondaryColor !important;
// }

// .p-dropdown:not(.p-disabled).p-focus {
//     border-color: $secondaryColor !important;
// }

// .p-dropdown.p-component.p-inputwrapper.p-focus.p-inputwrapper.focus {
//     border-color: $secondaryColor !important;
// }

// .p-dropdown.p-component.p-inputwrapper.p-dropdown-clearable.p-inputwrapper-filled.p-inputwrapper-focus {
//     border-color: $secondaryColor !important;
// }

// .p-dropdown.p-dropdown-label.p-placeholder {
//     color: #fff;
// }