@import '../../index.scss';

.DateFilter {
    position: absolute;
    top: 50px;
    left: 6px;
    background-color: white;
    border-radius: 6px;
    z-index: 1;
    padding: 10px 5px 10px 6px;

    .p-menu {
        border: 0px !important;
        width: 367px;
    }

    .p-menu ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
            margin: 1px 0;

            span {
                font-weight: 500;
            }
        }

    }

    .p-menu .p-menuitem-link {
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding: 0.75rem;
        margin: 3px 5px;
        box-shadow: 0 0 7px lightgray;
    }

    .active-date {
        a {
            background: $primaryColor;

            span {
                color: white !important;
            }
        }

        a:hover {
            background: $primaryColor !important;
        }
    }

    .p-menu .p-menuitem-link:focus {
        background: $primaryColor;

        span {
            color: white !important;
        }
    }

    .p-calendar {
        margin: 20px 0 10px 5px;
        width: 367px;

    }

    .p-calendar .p-inputtext {
        font-weight: 700;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
    }

    .active-calendar {
        input {
            background: $primaryColor !important;
            color: white !important;
        }
    }
}