@import '../../../../index.scss';

.export-dialog {
    .p-dialog-header {
        border-bottom: 0 none;
        background: $primaryColor !important;
        color: white !important;
        padding: -1.5rem !important;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;

        .pi {
            color: white;
        }
    }
    .p-dialog-content {
        padding-top: 25px;
        width: 600px;

        .p-progressbar {
            display: none !important;
        }
    }

    .p-button.p-button-danger,
    .p-buttonset.p-button-danger>.p-button,
    .p-splitbutton.p-button-danger>.p-button,
    .p-fileupload-choose.p-button-danger {
        display: none;
    }

    .p-button.p-button-success,
    .p-buttonset.p-button-success>.p-button,
    .p-splitbutton.p-button-success>.p-button,
    .p-fileupload-choose.p-button-success {
        display: none;
    }
    .custom-choose-btn {
        border-radius: 6px !important;
        background: $primaryColor;
        .pi-plus {
            display: none;
        }
    }
    .data-type-header {
        margin: 15px auto;
        font-size: large;
        font-weight: 600;
    }
    .pair{
        display: flex;
        justify-content: center;
        // flex-direction: column-reverse;
        .content{
            width: 100%;
        }
    }

    .button-area {
        display: flex;
        justify-content: center;

        .p-button {
            padding: 3px;
            color: white !important;
            width: 150px !important;
        }
    }

    .radio-button-group{
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        height: 42px;
        padding-top: 10px;

        .selection{
            display: flex;
        }

        label{
            font-weight: 600;
        }
    }

    .fieldset{

        border: lightgray solid 1px;
        /* border-width: thin; */
        border-radius: 6px;
    }

}