.zoom-block {
  position: fixed;
  bottom: 70px;
  left: 24px;
  display: flex;
  flex-direction: column;
  padding: 3px 0 3px 0;

  .p-button {
    height: 44px;
    width: 44px;
    margin-bottom: 8px;
  }

  .pi-home,
  .pi-arrow-circle-right,
  .pi-plus,
  .pi-minus {
    color: white;
  }
  .zoom-button{
    background-color: #326A87;
  }
}
