@import "../../index.scss";

.sidebar-container {
  background: $secondaryColor;
  border: 1px solid $secondaryColor;
  border-radius: 6px;
  position: absolute;
  top: 53px;
  max-width: 306px;
  pointer-events: all;
  max-height: calc(100vh - 25rem);
  width: 265px;
  overflow: auto;
  color: #000000;
  margin-left: 5px;

  .sidebar {
    overflow: auto;
    height: 100%;
    width: 100%;
  }

  // ---sidebar start
  .p-tree {
    border: none !important;
    padding: 5px !important;
    overflow-y: auto;
  }

  .p-tree .p-tree-container .p-treenode {
    color: #000000;
    font-weight: 400;
    font-size: 15px;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    &:focus {
      box-shadow: none;
    }
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: $primaryColor;
    color: $secondaryColor;
    font-weight: bolder;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-close,
  .p-sidebar .p-sidebar-header .p-sidebar-icon {
    color: $secondaryColor;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: $secondaryColor;
    background-color: $primaryHoverColor;
  }

  .p-sidebar .p-sidebar-header {
    display: flex;
    padding: 1.25rem 0.75rem 1.5rem 1.8rem;
    color: $secondaryColor;
    font-weight: 600;
    font-size: larger;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler {
    color: $primaryFocusColor;

    &:hover {
      color: $primaryFocusColor;
    }
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: $secondaryColor;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    &:focus {
      box-shadow: none;
    }
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    background: transparent !important;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    color: $secondaryColor;
    background: $primaryFocusColor;
  }

  .p-tree-toggler-icon.pi.pi-fw.pi-chevron-right {
    color: $secondaryColor !important;
  }

  .p-tree-toggler-icon.pi.pi-fw.pi-chevron-down {
    color: $secondaryColor !important;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box {
    border: 2px solid #ced4da;
    box-shadow: none !important;
    border-color: none !important;

    &:hover {
      // border: 2px solid $secondaryColor;
      box-shadow: none;
    }

    &:focus {
      border: 2px solid $secondaryColor;
      box-shadow: none;
    }
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    background: $secondaryColor !important;
    border: 2px solid $secondaryColor !important;
    border-color: #ced4da !important;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
    border: 2px solid #ced4da;
    box-shadow: none;
    color: $secondaryColor !important;
    background: $primaryFocusColor !important;

    &:hover {
      border-color: $secondaryColor;
      background: $secondaryColor;
      color: $secondaryColor;
    }
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border: 2px solid #ced4da !important;
    border-color: $secondaryColor !important;
  }

  .p-tree-container {
    overflow: hidden;
  }

  .p-treenode-label {
    font-weight: bold;
    // font-weight: bolder;
  }

  .p-tree .p-treenode-children {
    padding-left: 27px;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    padding: 5px;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-close:focus,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    // box-shadow: 0 0 0 0.1rem #6c757d;
    box-shadow: none;
  }

  .p-sidebar .p-sidebar-content {
    padding: 1.25rem 0 1.25rem 1.25rem;
    overflow: hidden;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: $secondaryColor !important;
  }

  .p-treenode-content.p-treenode-selectable {
    color: $secondaryColor;
    background-color: $primaryFocusColor;
  }

  .kkn {
    background: pink !important;
    color: green !important;

    // .p-treenode-content.p-treenode-selectable {
    // .p-treenode {

    // color: $secondaryColor;
    // background-color: purple !important;
    // }

    // }
  }

  .p-treenode-content.p-treenode-selectable:hover {
    color: $secondaryColor;
    background-color: $primaryFocusColor;
  }

  .p-treenode.p-treenode-leaf {
    background-color: $secondaryColor !important;
    color: $primaryFocusColor !important;

    .p-treenode-content.p-treenode-selectable {
      background-color: $secondaryColor;
      color: $primaryFocusColor;
      font-weight: bolder;
    }

    .p-treenode-content.p-treenode-selectable.p-highlight {
      background-color: $secondaryColor !important;
      color: $primaryFocusColor !important;
      font-weight: bolder !important;
    }

    .p-tree.p-tree-container.p-treenode.p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
      background: $secondaryColor !important;
      color: $primaryFocusColor !important;
    }

    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
      background: $secondaryColor !important;
      color: $primaryFocusColor !important;
    }
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-children .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #dce9fd !important;
    color: $primaryFocusColor !important;
  }

  // .p-treenode-children > .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover{
  //     background: $secondaryColor !important;
  //         color: $primaryFocusColor !important;
  // }

  .p-treenode-content .p-treenode-selectable {
    background: $thirdColor !important;
  }

  // .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  //     background-color: $secondaryColor !important;
  // }

  .p-treenode-content.p-treenode-selectable.p-highlight {
    background-color: $primaryFocusColor !important;
    color: $secondaryColor;
  }

  .p-checkbox-box.p-component {
    border: 1px solid $primaryFocusColor !important;
  }

  .p-checkbox-icon.p-c.pi.pi-minus {
    &::before {
      color: $primaryFocusColor;
    }
  }

  .p-treenode-content.p-treenode-selectable.p-highlight .p-treenode-label {
    width: 155px;
    overflow-wrap: break-word;
  }

  .p-treenode-content.p-treenode-selectable.p-treenode-selectable .p-treenode-label {
    width: 155px;
    overflow-wrap: break-word;
  }

  // -------sidebar
}

.p-treenode-leaf {
  .p-tree-toggler {
    width: auto !important;
    display: none;
  }
}

.p-treenode-content {
  .p-checkbox {
    order: 3;
    margin-left: auto;
  }
}

.p-treenode {
  padding: 0 0 3px 0 !important;
  // padding-bottom: 2px !important;
}

.p-treenode-icon {
  // width: 20px;
  // height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-menuitem.hidden-context-menu {
  display: none !important;
}

#external-hidden {
  visibility: hidden !important;
}

#non-external-visible {
  left: 228px !important;
  visibility: visible !important;
  width: 13.5rem;
}

.export-dialog {
  .p-dialog-header {
    border-bottom: 0 none;
    background: $primaryColor !important;
    color: $secondaryColor !important;
    padding: 1rem !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .pi-times {
      color: $secondaryColor;
    }
  }

  .p-dialog-header-icon:enabled:hover {
    color: #343a40;
    border-color: transparent;
    background: #183d526b !important;
  }

  .p-dialog-content {
    padding: 30px;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 350px;

    label {
      margin-left: 5px;
    }
  }

  .radio-button-group {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 15px;
  }

  .export-button {
    margin-top: 15px;
    width: 100% !important;
    color: $secondaryColor;
  }
}

.layer-delete-dialog {
  .p-dialog-header {
    border-bottom: 0 none;
    background: $primaryColor !important;
    color: $secondaryColor !important;
    padding: 1rem !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .pi {
      color: $secondaryColor;
    }

    .p-dialog-title {
      display: flex;
      justify-content: space-between;

      .minimize {
        padding-right: 0 !important;
        cursor: pointer;
        box-shadow: none !important;
        height: 0 !important;

        .pi-window-minimize {
          font-size: 15px !important;
        }

        &:hover {
          background: none !important;
        }
      }
    }
  }

  .p-dialog-content {
    background: $secondaryColor;
    color: $secondaryColor;
    padding: 20px;
    border-radius: 0 0 6px 6px;
    overflow: hidden;

    p {
      color: #000000;
    }
  }

  .p-dialog-header-icon:enabled:hover {
    color: #343a40;
    border-color: transparent;
    background: #183d526b !important;
  }

  .buttons {
    .accept {
      background-color: #e24c4c;
      color: $secondaryColor;
      width: 110px !important;

      &:hover {
        background-color: $thirdHoverColor !important;
        color: $secondaryColor;
      }
    }

    .reject {
      color: $secondaryColor;
      background-color: $primaryColor;
      width: 75px !important;

      // &:hover {
      // color: $primaryColor;
      // background-color: $secondaryColor !important;
      // }
    }
  }
}

.sidebar-spinner {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 43%;
}

/*
.p-button .p-component .node-button :hover {
  border-color: transparent !important;
  background: #fff !important; 
  border: none !important;
}
*/
.p-button.node-button:hover {
  border-color: transparent !important;
  background: #fff !important;
  border: none !important;
}

button.p-button.p-component.node-button {

  width: 20px !important;
  height: 20px !important;
  background-color: #fff;
  padding: 0;
  box-shadow: none;

}

.main-node {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: "center";
  margin-right: 3%;
}

.opacity {
  display: flex;
  padding: 3px 16px 6px 16px;
  flex-direction: column;
  justify-content: center;

  .opacity-header {
    margin-left: 23px;
    padding-bottom: 3px;
  }

  .custom-slider {
    margin-left: 23px;
  }
}

.custom-slider {
  -webkit-appearance: none;
  background: #61A6C6;
  border-radius: 5px;
  cursor: pointer;
  height: 6px;
  margin: 5px;
  outline: none;
  position: relative;
  top: 2px;
  transition: opacity .2s;
  width: 130px;
}