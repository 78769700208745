@import '../../index.scss';

.Table {
    .close-button {
        width: 32px !important;
        height: 30px !important;
        // background: $thirdColor;
        // color: $secondaryColor;
        background: none;
        border: none;
        box-shadow: none !important;
        color: $thirdColor !important;

        .pi-times {
            font-size: 17px;
            font-weight: 600;
        }

        &:hover {
            color: $secondaryColor !important;
            background: $thirdHoverColor !important;
        }
    }

    .left-button-group {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .p-button {
            min-width: fit-content;
            height: 32px !important;
            padding: 0 0.5rem !important;
            box-shadow: 0px 1px 6px 0px rgb(0 57 24 / 40%) !important;
            background: $primaryFocusColor;
            color: $secondaryColor;

            .p-button-label {
                font-weight: 500 !important;
            }

            &:hover {
                background: $primaryFocusColor !important;
                color: $secondaryColor !important;
            }
        }

    }

    .count {
        font-weight: 500;
        width: 150px;
        display: flex;
    }
}