@import "../../index.scss";

.App {
  width: 100%;
  height: 100%;

  .toolbar {
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  .basemaps {
    position: absolute;
    z-index: 1;
    bottom: 0;
  }

  .remove-bottom-left-icons {
    display: none !important;
  }

  .logo {
    position: absolute;
    z-index: 1;
    left: 88px;
    bottom: 10px;
  }

  .iframe {
    position: absolute;
    top: 70px;
    right: 0px;
    visibility: hidden;
    width: 90%;
    height: 90%;
  }

  .dashboard-opened {
    visibility: visible;
    z-index: 10;
  }

  .legend-container {

    .legend {
      position: fixed;
      // right: -114px;
      // bottom: 158px;
      right: -76px;
      bottom: 120px;
      width: auto;
      height: 32px;
      z-index: 1;
      display: flex;
      align-items: center;
      color: $secondaryColor;
      transform: rotate(-90deg);
      transition: .4s;
      cursor: pointer;
      opacity: .9;
      border-radius: 6px 6px 0 0px;
    }

    .tabmenu-head {
      background: $primaryFocusColor;
      position: fixed;
      right: 0;
      bottom: 15px;
      width: 32px;
      height: 32px;
      z-index: 1;
      justify-content: center;
      display: flex;
      align-items: center;
      color: $secondaryColor;
      transform: rotate(270deg);
      transition: 0.4s;
      cursor: pointer;
      opacity: .9;
    }

    .p-tabmenu {
      height: 32px;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
      background: $primaryFocusColor !important;
      color: $secondaryColor;
      border-left: 2px solid $primaryColor;
      border-radius: 0;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
      background: $primaryColor !important;
      color: $secondaryColor;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
      height: 32px;
    }

    .p-tabmenu-nav .p-menuitem-text {
      padding-bottom: 4px;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
      box-shadow: none;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
      color: $secondaryColor;
    }
  }

  // .table {
  //   height: 0;
  //   visibility: hidden;
  //   transition: 0.2s;
  // }

  // .table-opened {
  //   visibility: visible;
  //   height: 425px;
  //   width: auto;

  //   tbody {
  //     overflow-y: auto !important;
  //   }
  // }
}

.Table {
  margin: 0;
  width: 100%;
  background-color: $primaryFocusColor;
  position: absolute;
  bottom: 0;
  z-index: 10;

  .table-buttons {
    width: 100% !important;
  }

}

.p-datatable-thead {
  position: sticky;
  top: 0;
}

.p-datatable {
  // height: 100% !important;
  overflow: hidden !important;
  position: relative !important;
}

.p-datatable-resizable>.p-datatable-wrapper {
  max-height: 250px;
  overflow: auto;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  height: 48px;
  padding: 0.8rem;
}

.p-datatable-scrollable .p-datatable-thead,
.p-datatable-scrollable .p-datatable-tbody,
.p-datatable-scrollable .p-datatable-tfoot {
  max-height: 180px;
}

.kkn-icon {
  bottom: 1vh;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  // z-index: 1000000000;
}