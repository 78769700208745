@import '../../index.scss';

.CreateKKN {

    // width: 405px;
    width: 440px;

    .form {
        padding: 0;
        overflow-x: hidden;
    }

    .container {
        padding: 5px 0 0 0;
        max-height: 590px;
        overflow-y: auto;
        // padding: 5px 40px 0 25px;
        overflow-x: hidden;
        width: 100%;

        .input-group {
            background: $secondaryColor;
            margin: 0px 15px 0 5px;


            .shadow-group {
                box-shadow: 0 0 5px #656565;
                border-radius: 5px;
                padding: 5px;
                margin-bottom: 10px;

                .radio-button-group {
                    padding: 20px;

                    .radio-button {
                        margin-right: 50px;
                    }
                }

                .active-calendar {
                    input {
                        color: black;
                        font-weight: 500;
                        font-size: 15px;
                        text-align: center;
                        cursor: pointer;
                    }
                }

                .inactive-calendar {

                    input {
                        color: black;
                        font-weight: 500;
                        font-size: 15px;
                        text-align: center;
                        cursor: pointer;
                    }
                }

                .city-group {
                    padding-right: 10px;

                    .p-field {
                        margin-bottom: 15px;
                        height: 35px;
                        padding: 0;
                        margin-right: 0;
                    }
                }

                .upstate-group {
                    padding-right: 10px;

                    .p-field {
                        margin-bottom: 15px;
                        height: 35px;
                        padding: 0;
                        margin-right: 0;
                    }
                }

                .parametric-options {
                    display: flex;
                    font-size: 16px;
                    font-weight: 500;
                    color: black;

                    .pi {
                        font-size: 20px;
                        margin-right: 48px;
                        padding-left: 12px;
                    }

                }

                .subtitle-groups {

                    .parametric-subtitle-options {
                        display: flex;
                        font-size: 16px;
                        font-weight: 500;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding-left: 50px;

                        .label {
                            color: black;
                            padding-bottom: 15px;
                        }

                        .subtitle-selection {
                            width: 175px;
                            display: flex;
                            justify-content: flex-end;
                            color: black;
                            margin-bottom: 15px;
                        }

                    }
                }
            }

            label {
                font-size: 16px;
                font-weight: 500;
                color: black;
            }

            input {
                width: 250px;
            }

            .p-dropdown {
                width: 250px;
            }

            .p-multiselect {
                width: 250px;

                .p-multiselect-label {
                    width: 220px;
                    text-overflow: ellipsis;
                }
            }
        }

    }

    .confirm-buttons {
        padding: 20px;
        margin: 0;

        .cancel {
            background: $thirdColor;
            color: $secondaryColor;
            border: 2px solid $thirdColor !important;

            &:hover {
                background: $thirdHoverColor !important;
            }
        }

        .save {
            background: $primaryColor !important;
            border: 2px solid $primaryColor !important;
            color: $secondaryColor;

            &:hover {
                background: $primaryHoverColor !important;
            }
        }
    }

    .p-dialog-header {
        border-bottom: 0 none;
        background: $primaryColor !important;
        color: $secondaryColor !important;
        padding: -1.5rem !important;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;

        .pi {
            color: $secondaryColor;
        }

        .p-dialog-title {
            display: flex;
            justify-content: space-between;

            .minimize {
                padding-right: 0 !important;
                cursor: pointer;
                box-shadow: none !important;
                height: 0 !important;

                .pi-window-minimize {
                    font-size: 15px !important;
                }

                &:hover {
                    background: none !important;
                }
            }
        }
    }

    .p-dialog-content {
        background: #fff;
        color: #fff;
        padding: 20px 0px 20px 20px;
        border-radius: 0 0 6px 6px;
        overflow: auto;
    }

    .p-dialog-header-icon:enabled:hover {
        color: #343a40;
        border-color: transparent;
        background: #183d526b !important;
    }

    .progress-spinner {
        position: absolute;
        z-index: 1;
        width: 50px;
        height: 50px;
        left: 45%;
        top: 40%;
    }

    .test {
        width: 80% !important;
        box-shadow: 1px 1px 1px 1px !important;
        background-color: white !important;
    }

    .test:hover {
        background-color: white !important;
        color: $primaryColor !important
    }
}

// .hiddenKKN {
//     visibility: hidden;
// }

.date-module {
    position: absolute;
    /* top: 306px; */
    margin-top: 15vh;
    /* left: 244px; */
    margin-left: 17vw;
    background-color: white;
    border-radius: 6px;
    z-index: 1;
    padding: 10px 5px 10px 6px;

    .p-menu {
        border: 0px !important;
        width: 367px;
    }

    .p-menu ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
            margin: 1px 0;

            span {
                font-weight: 500;
            }
        }

    }

    .p-menu .p-menuitem-link {
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding: 0.75rem;
        margin: 3px 5px;
        box-shadow: 0 0 7px lightgray;
    }

    .active-date {
        a {
            background: $primaryColor;

            span {
                color: white !important;
            }
        }

        a:hover {
            background: $primaryColor !important;
        }
    }

    .p-menu .p-menuitem-link:focus {
        background: $primaryColor;

        span {
            color: white !important;
        }
    }

    .p-calendar {
        margin: 20px 0 10px 5px;
        width: 367px;

    }

    .p-calendar .p-inputtext {
        font-weight: 700;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
    }
}