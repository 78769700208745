@import "../../index.scss";

.UserInfoCard {
  position: absolute;
  top: 60px;
  right: 0.5%;
  background: $secondaryColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  border-radius: 6px 6px 6px 6px;
  padding: 3px;

  .menu-yetkiler {
    display: flex;
    flex-direction: column;
    display: none;

    span {
      cursor: pointer;
      font: normal normal normal 15px Roboto;
      letter-spacing: 0px;
      color: $primaryFocusColor;
      margin: 5px;
    }
  }

  .filter-blue {
    filter: invert(33%) sepia(65%) saturate(524%) hue-rotate(157deg) brightness(88%) contrast(86%);
  }

  .profile-block {
    width: 97%;
    display: flex;
    margin-left: 5%;
    margin-top: 4%;
    margin-bottom: 3%;
  }

  .menu-yardim {
    display: flex;
    flex-direction: column;
    display: none;

    span {
      cursor: pointer;
      font: normal normal normal 15px Roboto;
      letter-spacing: 0px;
      color: $primaryFocusColor;
      margin: 5px;
    }
  }

  .menu-yetkiler.opened {
    display: flex;
    background: $secondaryColor;
    border-radius: 0px 0px 6px 6px;
    margin-top: 2%;
  }

  .menu-yardim.yardim-opened {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-left: 3%;
    padding-left: 4%;
    margin-bottom: 3%;
    margin-top: 2%;
    width: 93%;
    background: $secondaryColor;
    border-radius: 0px 0px 6px 6px;
  }

  .doc-path {
    margin-bottom: 3%;
  }

  .auth-path {
    margin-bottom: 3%;

    a {
      cursor: pointer;
      font: normal normal normal 15px Roboto;
      letter-spacing: 0px;
      color: $primaryFocusColor;
      margin: 5px;
    }
  }

  .doc-path:hover {
    background-color: $primaryColor;
    border-radius: 3px;

    .sub-doc {
      color: $secondaryColor;
    }
  }

  .menu-yetkiler>div:hover {
    background-color: $primaryColor;
    border-radius: 3px;

    .auth-value {
      color: $secondaryColor;
    }
  }

  .auth-button {
    background-color: $primaryFocusColor;
    font-size: 15px;
    width: 100% !important;
  }

  .auth-button {
    &:hover {
      background-color: $primaryFocusColor;
    }
  }

  .clicked {
    background-color: $primaryFocusColor;
    font-size: 15px;
    width: 100% !important;

    &:hover {
      background-color: $primaryFocusColor;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    margin: 15px;
  }

  .p-button.p-component {
    width: 190px !important;
    margin: 3px;
    color: $secondaryColor;
  }

  .p-button.p-component.logout-button {
    margin-bottom: 5%;
    margin-top: 5%;
    width: 100px !important;
    background-color: $thirdColor;
    text-align: center !important;

    &:hover {
      background-color: $thirdHoverColor !important;
    }
  }

}