@import "../../index.scss";


.date-based-stats-redesign {
    // top: -60px !important;
    visibility: visible !important;
}


.date-based-stats {
    position: absolute;
    width: 700px;
    height: calc(100vh - 5rem);
    background: rgb(255, 255, 255);
    border-radius: 6px;
    overflow: hidden;
    padding: 10px;
    right: 5px;
    opacity: 0.9;

    .warning {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        background: $secondaryColor;
        font-size: 19px;
        z-index: 1;
        padding: 20px;
        margin-top: 55px;
        width: 100%;
        height: calc(100vh - 10rem);
        opacity: 0.8;

        .pi-exclamation-triangle {
            font-size: 50px;
            color: $thirdHoverColor;
        }
    }

    .warning-chart {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        background: $secondaryColor;
        font-size: 19px;
        z-index: 1;
        padding: 20px;
        width: 96.5%;
        height: calc(100vh - 32.5rem);
        opacity: 1;

        .pi-exclamation-triangle {
            font-size: 50px;
            color: $thirdHoverColor;
        }
    }

    .warning-stats {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        background: $secondaryColor;
        font-size: 19px;
        z-index: 1;
        padding: 20px;
        width: 96.5%;
        margin-left: 2px;
        height: calc(100vh - 46.5rem);
        opacity: 1;

        .pi-exclamation-triangle {
            font-size: 50px;
            color: $thirdHoverColor;
        }
    }

    .subgroup {
        display: flex;
        justify-content: space-between;
        // padding-top: 20px;
        padding-top: 12px;

        .selection-group {
            display: flex;

            .select-kkn-group {
                height: 40px;
                background: $primaryColor;
                box-shadow: 0px 3px 6px rgb(0 57 24 / 51%) !important;

                .p-dropdown-label {
                    color: $secondaryColor !important;
                    padding-top: 8px;
                }

                .pi-chevron-down {
                    color: $secondaryColor !important;
                }
            }

            .back-button {
                width: 40px !important;

                .pi-times {
                    color: $secondaryColor;
                }
            }

            .go-to-layer-button {
                width: 120px !important;
                color: $secondaryColor;

                .p-button-label {
                    font-weight: 400;
                }
            }
        }


        .download-group {
            display: flex;

            .pi-image,
            .pi-file-pdf {
                color: $secondaryColor;
            }
        }
    }

    .p-dropdown:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }

    //illerin trafik kaza istatistikleri
    .StatisticsByProvince {
        .chart-group {
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            height: calc(100vh - 14rem);
            background: rgb(230, 230, 230);
            margin-bottom: 10px;
            border-radius: 6px;

            h3 {
                text-align: center;
                padding-top: 10px;
            }

            .province-chart {
                padding-top: 10px;
            }
        }

        .description-group {
            display: flex;
            justify-content: center;
            align-items: center;

            .zoom-icon {
                margin-right: 10px;
            }

            h5 {
                font-size: 15px;
            }

        }
    }

    .date-based-data-resize {
        height: 85vh !important;
        visibility: visible !important;
    }



    .date-based-data {
        // height: calc(100vh - 10.5rem);
        height: calc(100vh - 6.5rem);
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        background: rgb(230, 230, 230);

        .first-row {
            display: flex;
            width: 100%;
            height: 52px;

            .box {
                display: flex;
                flex-direction: column;
                padding: 3px;
                border: 1px solid lightgray;
                margin: 2px;
                // width: 145px;

                .header {
                    color: $fourthColor;
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .desc {
                    display: flex;
                    font-size: 20px;
                    color: $thirdHoverColor;
                }
            }
        }


        .row-structure {
            border: 2px solid lightgray;
            // margin: 10px 0 0 0;
            margin: 5px 0 0 0;
            padding-top: 5px;
            padding-bottom: 5px;

            .second-row {
                display: flex;
                // justify-content: space-between;
                flex-direction: column;

                .second-row-title {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 3px;
                    height: 25px;
                    // border: 1px solid lightgray;
                    margin: 2px;
                    background: rgb(230, 230, 230);



                    .header {
                        color: $fourthColor;
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 5px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    }

                }

                .second-row-body {
                    display: flex;
                }

                .box {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 6px 3px 5px 3px;
                    height: 75px;
                    border: 1px solid lightgray;
                    margin: 2px;
                    background: rgb(230, 230, 230);
                    width: 100%;

                    .top-box {
                        display: flex;
                        justify-content: center;
                    }

                    .bottom-box {
                        margin-bottom: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .arrow {
                            font-weight: bold;
                            font-size: 33px;
                            margin-left: 3px;
                        }

                        .desc {

                            display: flex;
                            align-items: baseline;

                            .pi-arrow-down,
                            .pi-arrow-up,
                            .pi-arrows-h {
                                font-size: 22px;
                                font-weight: bold;
                                margin-left: 4px;
                            }
                        }

                    }

                    .header {
                        color: $fourthColor;
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 5px;
                    }

                    .desc {
                        display: flex;
                        justify-content: flex-end;
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
        }


        .pie-chart {
            width: 340px !important;

            canvas {
                width: 100%;
                background: rgb(230, 230, 230);
                display: flex;
                flex-direction: column;
            }
        }

        .download-group {
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;

            .pi-image,
            .pi-file-pdf {
                color: $secondaryColor;
            }

            .remove-shadow {
                box-shadow: none !important;
            }
        }
    }

    .kkn-statistics {
        padding: 5px 2px 0 2px;
        height: calc(100vh - 10.5rem);
        background: rgb(230, 230, 230);


        .header {
            padding-left: 5px;
            font-weight: 600;
            color: $primaryFocusColor;
        }

        .second-group-header {
            font-weight: 600;
            color: $primaryFocusColor;
        }

        .first-group,
        .second-group,
        .third-group {
            padding: 5px 5px 0 5px;
            margin-bottom: 5px;
            display: flex;
            flex-wrap: wrap;
            border: 1px solid lightgray;
            border-radius: 6px;

            .description {
                width: 100%;
            }

            .label {
                margin-bottom: 3px;

                span {
                    color: rgb(67 67 67);
                    font-weight: 600;
                }
            }

            .result {
                padding: 5px 10px;
                margin-bottom: 10px;
                background: $secondaryColor;
                border-radius: 6px;
                color: rgb(20, 20, 20);
            }

            .input-groups {
                margin-right: 10px;
            }

            .fifth-row {
                display: flex;
                justify-content: space-between;

                .box {

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 3px;
                    height: 75px;
                    border: 1px solid lightgray;
                    margin: 2px;
                    background: rgb(230, 230, 230);

                    .header {
                        color: $fourthColor;
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 12px;
                    }

                    .desc {
                        display: flex;
                        justify-content: flex-end;
                        font-size: 20px;
                    }
                }


            }
        }

        .fourth-row {
            display: flex;
            width: 100%;
            height: 100%;
            border-radius: 6px;

            .pie-chart {

                canvas {
                    height: 100%;
                    width: 100%;
                }
            }

        }

    }

    .active-calendar {
        input {
            background: #E24C4C !important;
            color: white !important;
            font-weight: 700;
            font-size: 15px;
            text-align: center;
            cursor: pointer;
        }
    }

    .inactive-calendar {

        input {
            // background: $primaryColor !important;
            // color: white !important;
            font-weight: 700;
            font-size: 15px;
            text-align: center;
            cursor: pointer;
        }
    }

}