@import "../../index.scss";


.right-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  
  .p-button {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.remove-toolbar{
  display: none !important;
}

.remove-for-analyse{
  visibility: hidden;
}

.Toolbar {
  display: flex;
  align-items: center;

  .search {
    display: flex;
    align-items: center;
    width: 420px;
    margin-right: 5px;

    .sidebar-button {
      margin: 5px;
    }
  }
  .statistic {
    position: absolute;
    top: 60px;
    right: 0;
  }


  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    position: relative;
    border-radius: 6px 0px 0px 6px;

    .left-content {
      display: flex;
      align-items: center;
      //width: 1020px;

      .button-groups {
        display: flex;
        position: relative;
        align-items: center;

        .date-info {
          background: white;
          width: 135px;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 3px 6px rgb(0 57 24 / 51%) !important;
          height: 37px;
          margin-top: 1px;
          color: $primaryFocusColor;
          font-weight: 600;
          font-size: 12.5px;
        }

        .remove-for-hr{
          visibility: hidden;
        }

        .hr {
          margin: 4px 3px 0px 3px;
          width: 5px;
          height: 42px;
          border-radius: 5px;
          // z-index: 100000;
          background: #61a6c6;
        }


        .filter-clean {
          background-color: $secondaryColor;
          font: normal normal medium 14px/19px Roboto;

          &:hover {
            box-shadow: 0px 3px 6px rgb(173, 173, 173) !important;
            color: $primaryColor;
            background-color: $secondaryColor !important;
          }

          span.p-button-icon.p-c.p-button-icon-left.pi.pi-times {
            margin-top: 3px !important;
          }
        }
      }

      .input-dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative !important;
        margin-left: 5px;
        width: 120px;
        height: 38px;
        background-color: $secondaryColor;
        color: grey;
        user-select: none;
        cursor: pointer;
        border-radius: 5px;
        margin: 0px 5px 0px 5px;
        padding: 5px;
        box-shadow: 0px 3px 6px #0039184d;

        span {
          width: 100%;
        }
      }

      input {
        width: 250px;
        height: 38px;
        border: none;
        outline: none;
        background-color: $secondaryColor;
        box-shadow: 0px 3px 6px #0039184d;
        color: grey;
        font-weight: 500;
        margin-right: 10px;

        &:focus {
          border: none;
          outline: none;
          box-shadow: 0px 3px 6px #0039184d;
          color: grey;
        }
      }

      .p-button {
        margin-right: 5px;
        margin-left: 5px;
      }
    }

    .right-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .button-groups {
        display: flex;
        align-items: center;

        .p-button {
          margin-left: 5px;
          margin-right: 5px;
          text-align: left;
        }

        .remove-for-hr{
          visibility: hidden !important;
          width: 0 !important;
        }

        .hr {
          margin: 4px 3px 0px 3px;
          width: 5px;
          height: 42px;
          border-radius: 5px;
          // z-index: 100000;
          background: #61a6c6;
        }
      }

      .user {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        z-index: 1;
        width: 180px;
        background-color: $primaryColor;
        color: $secondaryColor;
        font: normal normal bold 14px/19px Roboto;
        letter-spacing: 0px;
        padding: 2px;
        cursor: pointer;
        background-color: $primaryColor;
        padding: 0px 20px 0 20px;
        border-radius: 6px 0px 0px 6px;
        user-select: none;
      }

      .selected {
        background-color: $primaryFocusColor;
        user-select: none;
      }

      .pi-spin {
        color: $secondaryColor;
      }
    }
  }
}

.dashboard {
  position: absolute;
  top: 60px;
  right: 0.5%;
  border-radius: 6px;
}

.statistic {
  position: absolute;
  top: 60px;
  right: 0;
}

.button-groups {

  .date-stats {
    cursor: pointer;
    color: $secondaryColor;
  }

  .p-button.p-button-sm .p-button-icon {
    font-size: 1.5rem;
  }
}