@import '../../../index.scss';

.coordinate-container {
    border-radius: 6px;
    color: $secondaryColor;
    font-family: var(--font-family);
    position: absolute;
    bottom: 10px;
    right: 35px;
    // z-index: 1000;
    z-index: 1;
    background-color: $primaryColor;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    cursor: pointer;
    user-select: none;

    .coordinate-button {
        width: 24px !important;
        height: 24px !important;
        background-color: $primaryFocusColor !important;
        color: $secondaryColor !important;
        border-color: white !important;
        margin-right: 6px !important;
    }

    .p-divider {
        height: 20px;
        width: 1px;
        margin: 5px;
    }

    .epsg {
        width: 80px;
        text-align: center;
    }

    .coordinate {
        width: 160px;
        text-align: center;
    }

    .scale {
        width: 90px;
        text-align: center;
    }

    .scale-select {
        position: absolute !important;
        bottom: 31px;
        right: 0px;
        max-width: 140px;
        background-color: $primaryColor;
        user-select: none;
        height: 30px;

        span {
            color: $secondaryColor !important;
            padding: 4px 0 0 15px;
        }

        .p-dropdown-item {
            background: $primaryFocusColor !important;
            color: $secondaryColor !important;
        }

        .ul {
            background: $primaryFocusColor !important;
        }
    }

    .p-dropdown-items-wrapper {
        background: $primaryFocusColor;
    }

}

.coordinate-container-button {
    position: absolute;
    right: 0;
    bottom: 10px;
    width: 32px !important;
    height: 30px !important;
    background: $primaryFocusColor;
    padding: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none !important;

    .pi {
        color: $secondaryColor;
        font-size: 20px;
    }
}

.remove-coordinate-button{
    display: none !important;
}

.ol-scale-bar {
    display: none;
}