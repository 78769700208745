@import "../../index.scss";


.stats-redesign-all {
    top: -60px !important;
}

.Statistic2 {

    .StatisticsByProvince {
        .province-loading {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: absolute;
            background: #eeeeee;
            font-size: 19px;
            z-index: 1;
            padding: 20px;
            width: 99%;
            /* height: calc(100vh - 65rem); */
            height: 93%;
            top: 5%;
            opacity: 0.8;
            border-radius: 3px;


            .progress-spinner {
                display: flex;
                left: 45%;
                top: 45%;
                z-index: 0;
            }
        }

    }


    position: absolute;
    width: 700px;
    height: calc(100vh - 5rem);
    background: rgb(255, 255, 255);
    border-radius: 6px;
    overflow: hidden;
    padding: 10px;
    right: 5px;
    opacity: 0.9;

    .warning {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        background: $secondaryColor;
        font-size: 19px;
        z-index: 2;
        padding: 20px;
        width: 100%;
        height: calc(100vh - 10.5rem);
        opacity: 0.8;

        .pi-exclamation-triangle {
            font-size: 50px;
            color: $thirdHoverColor;
        }
    }

    .subgroup {
        display: flex;
        justify-content: space-between;
        // padding-top: 20px;
        padding-top: 12px;

        .selection-group {
            display: flex;

            .remove-shadow {
                box-shadow: none !important;
            }

            .select-kkn-group {
                height: 40px;
                background: $primaryColor;
                box-shadow: 0px 3px 6px rgb(0 57 24 / 51%);


                .p-dropdown-label {
                    color: $secondaryColor !important;
                    padding-top: 8px;
                }

                .pi-chevron-down {
                    color: $secondaryColor !important;
                }
            }

            .back-button {
                width: 40px !important;

                .pi-times {
                    color: $secondaryColor;
                }
            }

            .go-to-layer-button {
                width: 120px !important;
                color: $secondaryColor;

                .p-button-label {
                    font-weight: 400;
                }
            }
        }


        .download-group {
            display: flex;

            .pi-image,
            .pi-file-pdf {
                color: $secondaryColor;
            }

            .remove-shadow {
                box-shadow: none !important;
            }
        }
    }

    .p-dropdown:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }

    //illerin trafik kaza istatistikleri
    .StatisticsByProvince {
        .chart-group-statistics {
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            height: calc(101vh - 14rem);
            background: rgb(230, 230, 230);
            margin-bottom: 10px;
            border-radius: 6px;

            h3 {
                text-align: center;
                padding-top: 10px;
            }

            .province-chart {
                padding-top: 10px;
            }
        }

        .description-group {
            display: flex;
            justify-content: center;
            align-items: center;

            .zoom-icon {
                margin-right: 10px;
            }

            h5 {
                font-size: 15px;
            }

        }
    }

    .general-resize {
        max-height: 75vh !important;
    }



    .General,
    .RoadCondition,
    .RoadCondition2,
    .RoadCondition3,
    .CrossingAndJunction,
    .MomentOfAccident,
    .MomentOfAccident2 {
        height: calc(100vh - 10.5rem);
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        background: rgb(230, 230, 230);

        .first-two-rows {
            display: flex;
            min-height: 130px;
            background: #eeeeee;

            .first-row {
                display: flex;
                width: 36%;
                flex-direction: column;
                justify-content: space-around;
                padding-top: 5px;


                .section {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    left: -15px;
                    position: relative;
                    top: 1px;

                    .title {
                        transform: rotate(270deg);
                        font-size: 15px;
                        font-weight: bold;
                        display: flex;
                        align-items: baseline;
                        border-bottom: 1px solid #61A6C6;

                        .title-text {
                            color: #286688;
                            font-family: Arial;
                            font-weight: 900;
                        }
                    }

                    .detail {

                        // height: 50px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        left: -15px;
                        position: relative;

                        .detail-text {
                            color: #61A6C6;
                            font-weight: bold;
                        }

                    }

                    .date-detail {
                        left: -12px !important;
                    }
                }

                .date-section {
                    left: -12px !important;
                }
            }

            .first-row-activated {
                padding-bottom: 8px !important;
                padding-top: 0px !important;
            }


            .second-row {
                display: flex;
                justify-content: space-between;
                width: 64%;
                position: relative;

                .load-screen {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    position: absolute;
                    background: #eeeeee;
                    font-size: 19px;
                    z-index: 1;
                    padding: 20px;
                    width: 99%;
                    /* height: calc(100vh - 65rem); */
                    height: 90%;
                    top: 5%;
                    opacity: 0.8;
                    border-radius: 3px;


                    .progress-spinner {
                        display: flex;
                        left: 36%;
                        top: 27%;
                        z-index: 0;
                    }
                }

                .title {
                    transform: rotate(270deg);
                    font-size: 15px;
                    font-weight: bold;
                    display: flex;
                    align-items: flex-end;
                    border-bottom: 1px solid #61A6C6;
                    left: -66px;
                    top: 54px;
                    position: absolute;

                    .title-text {
                        color: #286688;
                        font-family: Arial;
                        font-weight: 900;
                    }
                }

                .second-row-section {
                    display: flex;
                    width: 100%;
                    padding-bottom: 4px;

                    .first-section-title-text {
                        position: absolute;
                        transform: rotate(270deg);
                        font-size: 15px;
                        color: #286688;
                        font-family: Arial;
                        font-weight: 900;
                        display: flex;
                        align-items: baseline;
                        top: 66px;
                        left: 27%;
                    }

                    .first-section-title-text::after {
                        position: absolute;
                        right: -4.5%;
                        top: 100%;
                        content: "";
                        width: 117px;
                        height: 1px;
                        background-color: #61A6C6;
                    }

                    .data-section {

                        padding: 3px 6px 0px 0px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .first-section {
                            display: flex;
                            justify-content: space-between;
                            height: 50%;
                            margin-bottom: 5px;

                            .accident {
                                font-size: 15px;
                                font-weight: bold;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-around;
                                border-radius: 5px;
                                padding: 3px;

                                .accident-data {
                                    font-weight: bold;
                                    font-size: 24px;

                                    .quote {
                                        color: red;
                                        font-weight: 100;
                                    }
                                }
                            }

                            .accident-red {
                                color: red
                            }

                            .accident-yellow {
                                color: #F9AB13
                            }

                            .accident-blue {
                                color: #0b57d0ff
                            }

                            .accident-purple {
                                color: purple;

                            }
                        }

                        .second-section {
                            display: flex;
                            justify-content: space-between;
                            height: 50%;

                            .accident {
                                font-size: 15px;
                                font-weight: bold;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-around;
                                padding: 3px;
                                border-radius: 5px;

                                .accident-data {

                                    font-weight: bold;
                                    font-size: 24px;


                                    .quote {
                                        color: red;
                                        font-weight: 100;
                                    }

                                }
                            }

                            .accident-red {
                                color: red
                            }

                            .accident-yellow {
                                color: #F9AB13
                            }

                            .accident-blue {
                                color: #0b57d0ff
                            }

                            .accident-purple {
                                color: purple;
                            }
                        }

                        .info {
                            color: red;
                            display: flex;
                            align-items: flex-end;
                            font-size: 9px;

                        }
                    }
                }
            }
        }


        .pie-chart {
            width: 340px !important;

            canvas {
                width: 100%;
                background: rgb(230, 230, 230);
                display: flex;
                flex-direction: column;
            }
        }
    }

    .kkn-statistics {
        padding: 5px 2px 0 2px;
        height: calc(100vh - 10.5rem);
        background: rgb(230, 230, 230);


        .header {
            padding-left: 5px;
            font-weight: 600;
            color: $primaryFocusColor;
        }

        .second-group-header {
            font-weight: 600;
            color: $primaryFocusColor;
        }

        .first-group,
        .second-group,
        .third-group {
            padding: 5px 5px 0 5px;
            margin-bottom: 5px;
            display: flex;
            flex-wrap: wrap;
            border: 1px solid lightgray;
            border-radius: 6px;

            .description {
                width: 100%;
            }

            .label {
                margin-bottom: 3px;

                span {
                    color: rgb(67 67 67);
                    font-weight: 600;
                }
            }

            .result {
                padding: 5px 10px;
                margin-bottom: 10px;
                background: $secondaryColor;
                border-radius: 6px;
                color: rgb(20, 20, 20);
            }

            .input-groups {
                margin-right: 10px;
            }

            .fifth-row {
                display: flex;
                justify-content: space-between;

                .box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 3px;
                    height: 75px;
                    border: 1px solid lightgray;
                    margin: 2px;
                    background: rgb(230, 230, 230);

                    .header {
                        color: $fourthColor;
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 12px;
                    }

                    .desc {
                        display: flex;
                        justify-content: flex-end;
                        font-size: 20px;
                    }
                }
            }
        }

        .fourth-row {
            display: flex;
            width: 100%;
            height: 100%;
            border-radius: 6px;

            .pie-chart {

                canvas {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

}