@import './../../../index.scss';

.p-button {
  width: 38px !important;
  height: 38px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin: 6px;
  background-color: #61A6C6;
  box-shadow: 0px 3px 6px lightgray !important;
  border-radius: 6px;
  border-color: transparent !important;
  border: none !important;
  color: #61A6C6;
  box-shadow: 0px 3px 6px #00391882 !important;

  .p-button:enabled:active {

    background-color: #61A6C6 !important;
    color: $secondaryColor !important;
  }

  &:hover {
    border-color: transparent !important;
    background: $primaryHoverColor !important;
    border: none !important;
    //box-shadow: 0px 3px 6px lightgray !important;
  }

  // span{
  //   position: absolute;
  //   top: 26px;
  //   right: 3px;
  // }
}

//aktif olan butonlar icin
.button-active {
  outline: none !important;
  border-color: transparent !important;
  background: $primaryFocusColor !important;
  color: $secondaryColor;
  border: none !important;
  box-shadow: 0px 3px 6px #00391882 !important;

  &:hover {
    border-color: transparent !important;
    border: none !important;
    box-shadow: 0px 3px 6px #00391882 !important;
  }
}

//filtreleme yapilmis butonlar icin 
.button-filter-active {
  outline: none !important;
  border-color: transparent !important;
  background: $thirdColor !important;
  color: $secondaryColor;
  border: none !important;
  box-shadow: 0px 3px 6px #00391882 !important;

  &:hover {
    border-color: transparent !important;
    border: none !important;
    box-shadow: 0px 3px 6px #00391882 !important;
    background: $thirdHoverColor !important;
  }
}

.p-tooltip .p-tooltip-text {
  background: grey;
  color: $secondaryColor !important;
  padding: 0.5em !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}