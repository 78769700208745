@import '../../index.scss';

.InfoPanel {
    // right: 75px;
    margin: 0;
    // max-width: 350px;
    // position: fixed !important;
    // top: 55px !important;

    .container {
        display: flex;
        justify-content: space-between;
        width: 600px;

        .left-group,
        .right-group {
            width: 100%;

            .input-group {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                max-width: 290px;

                label {
                    color: #343a40;
                    font-weight: 500;
                }

                .p-inputtext {
                    height: 35px;
                }
            }
        }
    }

    .p-dialog-header {
        border-bottom: 0 none;
        background: $primaryColor !important;
        color: $secondaryColor !important;
        padding: 5px 10px !important;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;

        .pi {
            color: $secondaryColor;
        }
    }

    .p-dialog-content {
        background: $secondaryColor;
        color: $secondaryColor;
        padding: 0 20px;
        border-radius: 0 0 6px 6px;
        max-height: 620px;
    }

    .p-dialog-header-icon:enabled:hover {
        color: #343a40;
        border-color: transparent;
        background: #183d526b !important;
    }

    .p-tabview {

        .p-tabview-panels {
            padding: 1rem 0;
        }

        li {
            a {
                padding: 1rem !important;
            }
        }

        .pi-box {
            margin-right: 5px;
            font-weight: 600;
        }
    }
}

.OthersInfoPanel {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    .input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;


        label {
            color: #343a40;
            font-weight: 500;
            margin-right: 5px;
        }
    }
}

.ListBox {

    .container {
        display: flex;
        justify-content: space-between;
        width: 600px;

        .left-group,
        .right-group {
            width: 100%;

            .input-group {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                max-width: 290px;

                label {
                    color: #343a40;
                    font-weight: 500;
                }

                .p-inputtext {
                    height: 35px;
                }
            }
        }
    }

    .p-dialog-header {
        border-bottom: 0 none;
        background: $primaryColor !important;
        color: $secondaryColor !important;
        padding: 5px 10px !important;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;

        .pi {
            color: $secondaryColor;
        }
    }

    .p-dialog-content {
        background: $secondaryColor;
        color: $secondaryColor;
        padding: 15px 15px;
        border-radius: 0 0 6px 6px;
        max-height: 540px;
    }

    .p-dialog-header-icon:enabled:hover {
        color: #343a40;
        border-color: transparent;
        background: #183d526b !important;
    }
}

.info-table {
    max-width: 770px;
    padding-top: 50px;

    .p-paginator-top {
        right: 0;
    }
}