@import '../../index.scss';

.AccidentFilters {
    position: absolute;
    top: 50px;
    left: 0px;
    display: flex;
    background: $secondaryColor;
    border-radius: 6px;
    opacity: 1;
    flex-direction: column;
    padding-left: 25px;
    overflow: auto;
    width: 481px;
    z-index: 1;
    max-height: calc(100% - 85px);
    min-height: calc(100vh - 453px);
    // min-height: calc(100vh - 426px);
    padding-top: 5px;

    .p-slider.p-component.p-slider-horizontal {
        width: 215px;
    }

    .p-slider-range {
        background: $primaryColor;
    }

    .p-slider-handle {
        border-color: $primaryFocusColor;
    }

    .p-slider:not(.p-disabled) .p-slider-handle:hover {
        background: $primaryColor ;
        border-color: $primaryColor;
    }


    table {
        width: 100%;
        max-height: calc(100% - 5rem);
        border-collapse: collapse;
        overflow-y: auto;
        height: calc(100% - 5rem);
    }

    tbody {
        overflow-y: auto;
        height: calc(100% - 5rem);
        max-height: calc(100% - 5rem);
    }


    tr {
        height: 60px;
        border-bottom: 1px solid #ccd5d8;
    }

    td {
        // width: 30px;
        margin: 5px 35px;
    }

    .apply-button {
        display: flex;
        position: absolute;
        // padding: 15px;
        padding: 15px 23px 8px 15px;
        bottom: 0;
        right: 0;
        z-index: 1;

        .apply {
            background-color: #E24C4C;
            color: $secondaryColor;
            width: 100px !important;

            &:hover {
                background-color: $thirdHoverColor !important;
                color: $secondaryColor;
            }

        }

        .clearAll {
            color: $secondaryColor;
            background-color: $primaryColor;
            width: 100px !important;

            &:hover {
                color: $primaryColor;
                background-color: $secondaryColor !important;
            }

        }

    }

    .p-multiselect {
        width: 445px;

        .p-multiselect-label {
            white-space: normal;
        }

        .p-multiselect-token {
            margin-bottom: 3px !important;
        }
    }
}