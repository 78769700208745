@import "../../index.scss";

.Legend {
    width: 200px;
    height: 100px;
    background: $primaryColor;
    display: flex;
    position: absolute;
    right: 35px;
    bottom: 45px;
    flex-direction: column;
    justify-content: space-evenly;
    opacity: .85;
    padding: 0 5px 5px 15px;
    border-radius: 6px;




    .lejant-info {
        display: flex;
        align-items: center;

        .color {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid $secondaryColor;
            margin-right: 5px;
        }

        .color-info {
            color: $secondaryColor;
        }

    }

}

.cluster-Legend {
   

    width: 220px;
    height: 183px;
    background: #61A6C6;
    display: flex;
    position: absolute;
    right: 35px;
    bottom: 45px;
    flex-direction: column;
    justify-content: space-evenly;
    opacity: 0.85;
    padding: 7px 0px 0px 8px;
    border-radius: 6px;


    .lejant-info {
        display: flex;
        align-items: center;

        .color {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid $secondaryColor;
            margin-right: 5px;
        }

        .color-info {
            color: $secondaryColor;
        }

    }

}