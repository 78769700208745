@import '../../../../index.scss';

.GoToLocation {

    .content {
        display: flex;

        button {
            color: $secondaryColor;
            box-shadow: none !important;
            margin-left: 5px !important;
            height: 42px !important;
        }
    }

    .p-dialog-header {
        border-bottom: 0 none;
        background: $primaryColor !important;
        color: $secondaryColor !important;
        padding: 5px 10px !important;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;

        .pi {
            color: $secondaryColor;
        }
    }

    .p-dialog-content {
        background: $secondaryColor;
        color: $secondaryColor;
        padding: 20px 10px;
        border-radius: 0 0 6px 6px;
        max-height: 540px;
    }

    .p-dialog-header-icon:enabled:hover {
        color: #343a40;
        border-color: transparent;
        background: #183d526b !important;
    }

    .p-inputtext {
        width: 250px !important;
    }

    ::placeholder {
        color: lightgray;
        opacity: 1;
        /* Firefox */
    }
}