@import "primereact/resources/themes/lara-light-blue/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

$primaryColor: #61A6C6;
$secondaryColor: #fff;

$primaryHoverColor: #5896b3;
$secondaryHoverColor: #fff;

$primaryFocusColor: #286688;
$focusBackgroundColor: #fff;

$thirdColor: #E24C4C;
$thirdHoverColor: #FF4242;

$fourthColor: #1A4158;


body,
html,
#root {
  font: normal normal bold 14px/19px Roboto;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: $fourthColor;
  font-weight: normal;
  user-select: none;
}

//scroll style

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f4f4f5;
}

::-webkit-scrollbar-thumb {
  background: $secondaryColor;
}

.p-paginator-top {
  position: absolute;
  top: 0;
  right: 60px;
  border: none;
  padding: 3px 0 0 0;
  border-width: 0 !important;
  border-top: 1px solid #dee2e6 !important;
  background: transparent !important;

  .p-button {
    background: $secondaryColor !important;
    min-width: 30px !important;
    max-width: 30px !important;
    height: 32px !important;
    box-shadow: 0px 1px 6px 0px rgb(0 57 24 / 40%) !important;
  }

  .p-dropdown {
    display: none;
  }

}

.p-paginator-page.p-highlight {
  background: $primaryHoverColor !important;
  color: $secondaryColor !important;
}

.p-paginator-page {
  min-width: 2rem !important;
  height: 2rem !important;
}

.p-paginator-first,
.p-paginator-last,
.p-paginator-prev,
.p-paginator-next {
  min-width: 2.5rem !important;
  height: 2.5rem !important;
}

.p-datatable {
  th {
    font-weight: 500 !important;
  }

  td {
    padding: 0.25rem 0.5rem;
  }

  .index {
    min-width: 5vw !important;
    max-width: 5vw !important;
  }
}

.p-tooltip .p-tooltip-text {
  background: $primaryFocusColor;
  color: rgba(255, 255, 255, 0.87);
  padding: 0.5rem 0.5rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #798f9a !important;
  background: $secondaryColor !important;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  background: $primaryFocusColor !important;
}

.progress-spinner {
  position: absolute;
  z-index: 10000;
  width: 50px;
  height: 50px;
  left: 45%;
  top: 40%;
}

.date-button {
  color: $secondaryColor;
  background: $thirdColor;
  padding: 15px;
  width: 130px !important;

  img {
    position: absolute;
    left: 10px;
  }

  span {
    font-weight: 500;
    padding-left: 25px;
    width: 100%;
    text-align: end;
  }

  &:hover {
    background: $thirdHoverColor !important;
  }
}

.active-date-button {
  outline: none !important;
  border-color: transparent !important;
  background: $primaryFocusColor !important;
  color: $secondaryColor;
  border: none !important;
  box-shadow: 0px 3px 6px #00391882 !important;
  
  &:hover {
    background: $primaryHoverColor !important;
    border-color: transparent !important;
    border: none !important;
    box-shadow: 0px 3px 6px #00391882 !important;
  }
}