@import '../../index.scss';

.container {
    width: 360px;

    .p-field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px;
    }
}

.confirm-buttons {
    margin: 20px;
    display: flex;
    justify-content: flex-end;

    .p-button {
        width: 100px !important;
        color: $primaryFocusColor;
        background-color: white;
        border: 2px solid $primaryFocusColor !important;
        box-shadow: unset !important;

    }
}