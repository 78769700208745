@import "../../index.scss";

.basemap-button {
    margin-right: 6px;
    display: flex;
    img {
      width: 55px;
      height: 55px;
      border: 2px solid $secondaryColor;
      border-radius: 6px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .basemap-group {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;
    background-color: $secondaryColor;
    border: 3px solid $secondaryColor;
    border-radius: 6px;
    height: 90px;
    .basemap {
      display: flex;
      background-color: $secondaryColor;
      flex-direction: column;
      align-items: center;
      border: 1px solid $secondaryColor;
      margin: 0px 5px;
      width: 47px;
      .image-box {
        border-radius: 5px;
        width: 48px;
        height: 48px;
        box-shadow: 1px 1px 5px black;
  
        &:hover {
          cursor: pointer;
        }
      }
      .title {
        font-size: 10px;
        padding: 0px 8px;
        margin: 2px;
        text-align: center;
        line-height: 15px;
        color: #7A8CA7;
        font-weight: lighter;
      }
    }
  }
  
  .Basemap {
    padding: 14px;
    display: flex;
    align-items: flex-end;
    user-select: none;
    width: 500px;
  }
  .Basemap-left {
    padding: 14px;
    display: flex;
    align-items: flex-end;
  }
  