@import '../../index.scss';

.container {
    width: 360px;

    .p-field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px;
    }

    .input-group {

        width: 100%;

        .input-old-pass {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .data-section {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }

        .message-section {
            display: flex;
            justify-content: flex-end;
        }

        .valid-password {
            color: green;
        }

        .text-danger {
            color: red;
        }

        .text-success {
            color: green;
        }

        .old-pass {
            width: 60.5%;
        }
    }
}

.confirm-password-buttons {
    margin: 30px 10px 0px 0px;
    display: flex;
    justify-content: flex-end;

    .p-button {
        width: 100px !important;
        color: $primaryFocusColor;
        background-color: white;
        border: 2px solid $primaryFocusColor !important;
        box-shadow: unset !important;

    }
}

.button-proceed {
    margin: 0px 10px 0px 0px;
    display: flex;
    justify-content: flex-end;

    .p-button {
        width: 100px !important;
        color: $primaryFocusColor;
        background-color: white;
        border: 2px solid $primaryFocusColor !important;
        box-shadow: unset !important;

    }
}