@import "../../index.scss";

// .cesium-viewer-bottom {
//     visibility: hidden;
// }

.cesium-credit-textContainer {
    visibility: hidden;
}

.cesium-credit-expand-link {
    visibility: hidden;
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: $secondaryColor;
    color: #343a40;
    padding: -1.5rem !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}