@import '../../index.scss';

.Search {
  width: 370px;

  .search-container {
    //position: relative;
    width: 356px;
    height: 38px;
    //padding: 0px 10px 0px 10px;
    font-size: 20px;
    box-shadow: 0px 3px 6px #0000003D;
    color: $secondaryColor;
    background: $secondaryColor;
    border-radius: 7px;
    margin-left: 5px;
  }

  input.p-inputtext.p-component {
    width: 360px;
    height: 38px;
    position: relative;
    border: none;
    margin-left: 5px;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $primaryColor;
    opacity: 1;
    /* Firefox */
  }


  .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: $secondaryColor;
  }


  .p-listbox.p-component {
    top: 52px !important;
    left: 53px !important;
    max-height: 80vh !important;
    min-height: auto !important;
    width: 26rem !important;
    background-color: rgba(221, 236, 240, 0.63) !important;
    display: flex;
    justify-content: center;
    position: absolute;
    font-weight: 500;
  }

  .p-listbox-list-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .item-template {
    border-radius: 5px !important;
    box-shadow: 0 0 5px #00391882 !important;
    padding: 0 !important;
    width: 328px !important;
    background-color: #ddecf0c2;

    &:hover {
      background: #aac8d0;
    }
  }

  .p-listbox .p-listbox-list {
    padding: 6px 0 0 0 !important;
  }

  .selected-item-template {
    cursor: default;
  }

  .p-listbox-item {
    padding: 0.6rem 1rem !important;
  }

  .item-template {
    cursor: pointer;
  }

  .close-button {
    padding-right: 10px;
    position: absolute;
    left: 352px;
    top: 20px;
    color: #61A6C6;

    &:hover {
      cursor: pointer;
    }
  }

  .pi-times,
  .pi-search {
    font-size: 16px;
  }

  .p-listbox-list {
    width: 100%;

    .p-listbox-item {
      width: 100%;

      .selected-item-template {
        .header {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;

          .pi-angle-left {
            font-size: 20px;
            padding-right: 10px;
          }
        }

        .tags {
          a {
            text-decoration: none;

            p {
              margin: 12px 0px 0px 32px;
              color: grey;
              text-decoration: underline;
              text-transform: capitalize;
              font-weight: 500;
            }
          }
        }

        .detail {
          padding-left: 9px;
        }

        .buttons {
          display: flex;
          margin: 20px;

          .route {
            // .p-button {
            //   height: 40px;
            //   img {
            //     width: 22px;
            //     margin-left: 7px;
            //   }
            // }
          }

          .share {
            // .p-button {
            //   width: 42px;
            //   height: 40px;
            // }
          }
        }
      }
    }
  }

  .icon-container {
    min-width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    .category-icon {
      width: 26px;
      height: 26px;
    }
  }

  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    // color: unset;
    // background: unset;
  }

  .p-listbox .p-listbox-list .p-listbox-item:focus {
    box-shadow: unset;
  }

  .pi-angle-left:hover {
    cursor: pointer;
  }

  .result-area {
    position: absolute;
    top: 61px;
    width: 365px;
    background-color: #ddecf0 !important;
    box-shadow: 0px 3px 6px #0039184d;
    border-radius: 5px;
    padding: 5px;

    .result-info {
      display: flex;
      align-items: center;

      .text {
        font-size: 11px;
        white-space: nowrap;
        color: grey;
      }
    }
  }

  .switch {
    padding-right: 10px;
    position: absolute;
    left: 372px;
    top: 16px;

    .p-inputswitch-slider {
      // background: #61A6C6;

      &:hover {
        // background: #61A6C6 !important;
        cursor: pointer;
      }
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
      background: red !important;
    }
  }
}