.draw-container {
    position: absolute;
    top: 50px;
    // left: 225px;
    left: 188px;
    display: flex;
    padding: 3px 0 3px 0;
    z-index: 1;
    opacity: 0.85;

    .p-button {
        height: 44px;
        width: 44px;
        margin-bottom: 5px;
    }
}