* {
  margin: 0;
  padding: 0;
}

#chart-container {
  position: absolute;
  min-height: 98vh;
  width: 99.5vw;
  right: 0.3%;
  overflow: auto;
  border-radius: 6px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  padding: 10px;
  user-select: none;
  z-index: 1;
  top: 16.5%;
}

.btn {
  color: white;
  width: 6% !important;
  margin-right: 3%;
}

.profile-icon {
  display: flex;
  align-items: center;
}

.chart-list {
  border-style: groove;
  border-radius: 10px;
  margin-top: 5%;
  width: 13%;
  height: 32.5%;
  display: flex;
  flex-direction: column;
  padding: 2% 1% 1% 1%;
  overflow-y: auto;
  position: fixed;
  overflow-y: auto;
  z-index: 111111;
}

.first-chart-list {
  margin-bottom: 5%;
}

.first-chart-list>ul>li :hover {
  background-color: #44758C;
  color: white;
}


.second-chart-list>ul>li :hover {
  background-color: #44758C;
  color: white;
}


.first-arrow {
  border: solid #286688;
  border-width: 0 3px 3px 0;
  display: inline-block;
  margin-left: 35%;
  padding: 3px;
}

.second-arrow {
  border: solid #286688;
  border-width: 0 3px 3px 0;
  display: inline-block;
  margin-left: 33%;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}