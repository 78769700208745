@import '../../index.scss';

iframe {
  width: 100%;
  height: 94vh;
  border-radius: 6px;
  border: none;
}

#all-container {
  position: absolute;
  top: 0px;
  width: 1500px;
  height: 100vh;
  background-color: white;
  // right: 1%;
  // overflow: auto;
  z-index: -1;
  border-radius: 6px;
  right: 0px;

  .my-superset-container {
    height: 100% !important;
    border-radius: 6px;
  }
}

.superset-navbar {
  position: absolute;
  display: flex;
  // justify-content: flex-end;
  padding: 10px;
  width: 100%;
  justify-content: flex-end;
  padding-right: 2%;
  align-items: center;

  .close {
    color: $fourthColor;
    -webkit-text-stroke-width: thick;
  }

  a {
    text-decoration: none;
  }

  i {
    cursor: pointer;
  }
}

.dashboard-list {
  z-index: 1000 !important;
  border: 2px;
  background-color: white;
  color: grey;
  border-radius: 6px 6px 6px 6px;
  padding: 3px;

  .list {
    display: flex;
    align-items: center;
    background: $primaryColor;
    color: $secondaryColor;
    height: 38px;
    margin: 3px;
    border-radius: 6px;
    padding: 6px;
  }

  li {
    cursor: pointer;
    color: $fourthColor;
    list-style: none;

  }
}